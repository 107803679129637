import { createSlice } from '@reduxjs/toolkit';

import { getOperators_req } from '../api/payment.api';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    drawerOpen: false,
    isMobile: null,
    isAndroid: null,
    isIOS: null,
    categories: null,
    providers: null,
    operators: null,
  },
  reducers: {
    setDrawerOpen: (state, action) => {
      state.drawerOpen = action.payload;
    },
    setDeviceData: (state, action) => {
      if (action.payload.isMobile !== undefined) {
        state.isMobile = action.payload.isMobile;
      }

      if (action.payload.isAndroid !== undefined) {
        state.isAndroid = action.payload.isAndroid;
      }

      if (action.payload.isIOS !== undefined) {
        state.isIOS = action.payload.isIOS;
      }
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setProviders: (state, action) => {
      state.providers = action.payload;
    },
    setOperators: (state, action) => {
      state.operators = action.payload;
    },
  },
});

export const {
  setDeviceData,
  setCategories,
  setProviders,
  setOperators,
  setDrawerOpen,
} = globalSlice.actions;

export function getOperators(cookies) {
  return async (dispatch) => {
    try {
      dispatch(setOperators(await getOperators_req(cookies)));
    } catch (e) {
      console.log('globalSlice -> getOperators Error:', e);
    }
  };
}

export default globalSlice.reducer;
